/**
 * SclEnquirePanel
 */

// template
const template = `
	<div :class="{ 'is-visible' : !isAtTop && !formVisible }">
		<v-container class="py-3">
			<v-layout row wrap>
				<v-flex xs12 sm7>
					<h4 class="scl-enquire-panel__title">find out how we can help</h4>
				</v-flex>
				<v-flex xs12 sm5>
					<v-btn class="scl-enquire-panel__btn" color="accent" depressed @click="scroll">Enquire Now</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
